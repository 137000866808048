<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_ginner_grower.profile') + ' ' + $t('globalTrans.search') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes" @change="setData" @returned-data="searchData" :selectedData="selectedData" :key="selectedData"/>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_ginner_grower.profile') + ' ' + $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template #cell(mobile_no)="data">
                                            {{ numberFormatBn(data.value) }}
                                        </template>
                                         <template v-slot:cell(land_area)="data">
                                            {{ $n(data.item.land_area) }}
                                        </template>
                                        <template #cell(status)="data">
                                            <span v-if="data.value == 1 " class="badge badge-success"><i class="fas fa-check"></i> {{ $t('globalTrans.approved') }}</span>
                                            <span v-else class="badge badge-warning"><i class="far fa-pause-circle"></i> {{ $t('globalTrans.onHold') }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                                <a href="javascript:" class="btn_table_action table_action_status" title="Click to Approve" v-if="data.item.status === 2 && isAdminTypeUser || (data.item.status === 2 && isOfficeUser && data.item.type === 2)" @click="toggle(data.item)">
                                                    <i class="fas fa-check"></i>
                                                </a>
                                                <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Hold" v-if="data.item.status === 1 && isAdminTypeUser || (data.item.status === 1 && isOfficeUser && data.item.type === 2)" else @click="toggle(data.item)">
                                                    <i class="far fa-pause-circle"></i>
                                                </a>
                                                <a href="javascript:" class="btn_table_action table_action_view" title="View" @click="view(data.item)"><i class="fas fa-eye"></i></a>
                                                <a href="javascript:" v-if="isAdminTypeUser || (isOfficeUser && data.item.type === 2)" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                                <a href="javascript:" v-if="isAdminTypeUser" class="btn_table_action table_action_toggle" title="Delete" @click="remove(data.item)"><i class="ri-delete-bin-line"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="xl" :title="formTitle" :hide-footer=true :ok-title="$t('globalTrans.close')">
            <FormV :item="currentItem" @submitted="loadData()"/>
        </b-modal>
        <b-modal id="view-modal" size="lg" :title="$t('globalTrans.details')" :hide-footer=true>
            <Show :item="currentItem"/>
        </b-modal>
    </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { agriMarketingServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { ggProfileList, ggProfileToggle, ggProfileDelete } from '../../api/routes'
import { helpers } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import Show from './Show'
import MasterList from '@/mixins/modal-base-master-list'
import { numberFormatBn } from '@/Utils/fliter'
import Custom from '@/store/custom'

export default {
    name: 'ListView',
    mixins: [MasterList],
    components: {
        FormV, FormSearch, Show
    },
    data () {
        return {
            search: {
              region_id: 0,
              zone_id: 0,
              unit_id: 0,
              district_id: 0,
              upazilla_id: 0
            },
            currentItem: null,
            upazilaList: [],
            rows: [],
            defaultNull: '',
            selectedData: {}
        }
    },
    computed: {
        unitList () {
            return this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1)
        },
        regionList: function () {
            return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
        },
        zoneList () {
            return this.$store.state.agriMarketing.commonObj.zoneList
        },
        districtList () {
            return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        },
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        orgList () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return !this.currentItem ? this.$t('cotton_ginner_grower.form') + ' ' + this.$t('globalTrans.entry') : this.$t('cotton_ginner_grower.form') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.type'), class: 'text-center' },
                { label: this.$t('globalTrans.region'), class: 'text-center' },
                { label: this.$t('globalTrans.district'), class: 'text-center' },
                { label: this.$t('globalTrans.upazila'), class: 'text-center' },
                { label: this.$t('seedsSeeds.unitName'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('globalUserData.mobile_no'), class: 'text-center' },
                { label: this.$t('globalTrans.address'), class: 'text-center' },
                { label: this.$t('cotton_ginner_grower.land_area_acre'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-20' }
            ]

            let keys = []

            if (this.$i18n.locale === 'en') {
                keys = [
                    { key: 'index' },
                    { key: 'type_en' },
                    { key: 'region_en' },
                    { key: 'district_en' },
                    { key: 'upazila_en' },
                    { key: 'unit_en' },
                    { key: 'name' },
                    { key: 'mobile_no' },
                    { key: 'address' },
                    { key: 'land_area' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'type_bn' },
                    { key: 'region_bn' },
                    { key: 'district_bn' },
                    { key: 'upazila_bn' },
                    { key: 'unit_bn' },
                    { key: 'name_bn' },
                    { key: 'mobile_no' },
                    { key: 'address_bn' },
                    { key: 'land_area' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'region_id',
                        type: 'Select',
                        label: 'globalTrans.region',
                        options: this.regionList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: false
                    },
                    {
                        name: 'district_id',
                        type: 'Select',
                        label: 'globalTrans.district',
                        options: this.districtList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: false
                    },
                    {
                        name: 'upazilla_id',
                        type: 'Select',
                        label: 'globalTrans.upazila',
                        options: this.upazilaList,
                        selectTitle: this.$t('globalTrans.select'),
                        readOnly: false
                    },
                    {
                        name: 'unit_id',
                        type: 'Select',
                        label: 'irrigation_config.unit_namel',
                        options: helpers.filterListLang(this.unitList, 'text', 'text_bn', 'value'),
                        selectTitle: this.$t('globalTrans.select'),
                        config: [{ disabled: true }],
                        readOnly: false
                    },
                    {
                      name: 'type',
                      type: 'Select',
                      label: 'cotton_ginner_grower.ginnerGrowerName',
                      options: helpers.filterListLang(Custom.ginnerGrower),
                      labelCol: 4,
                      selectTitle: this.$t('globalTrans.select')
                    }
                ]
            }
        },
        isOfficeUser () {
          return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
        },
        isAdminTypeUser () {
            return (this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
        }
    },
    created () {
    //   if (this.isOfficeUser) {
    //     const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
    //     const unit = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1).find(obj => obj.district_id === authOfficeDetail.district_id)
    //     if (unit !== undefined) {
    //       this.search.region_id = unit.region_id
    //       this.search.zone_id = unit.zone_id
    //       this.search.unit_id = unit.value
    //       this.search.district_id = unit.district_id
    //       this.search.upazilla_id = unit.upazilla_id

    //       this.selectedData = Object.assign({}, this.selectedData, {
    //         region_id: unit.region_id,
    //         zone_id: unit.zone_id,
    //         unit_id: unit.value,
    //         district_id: unit.district_id,
    //         upazilla_id: unit.upazilla_id
    //       })
    //     }
    //   }
    },
  mounted () {
        this.loadData()
    },
    methods: {
        remove (item) {
            helpers.swalInit(this.$t('globalTrans.deleteConfirmMsg')).then(async (result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                    await RestApi.deleteData(agriMarketingServiceBaseUrl, ggProfileDelete + '/' + item.id).then(response => {
                        if (response.success) {
                            RestApi.postData(authServiceBaseUrl, '/user/destroy-user', { mobile_no: response.mobile_no }).then(response => {
                                if (response.success) {
                                    this.loadData()
                                }
                                helpers.successErrorToaster(response, false, this.$t('globalTrans.deleteMsg'))
                            })
                        }
                    })
                }
            })
        },
        edit (item) {
            this.currentItem = item
        },
        toggle (item) {
            helpers.swalInit().then((result) => {
                if (result.isConfirmed) {
                    RestApi.deleteData(agriMarketingServiceBaseUrl, ggProfileToggle + '/' + item.id).then(response => {
                        if (response.success) {
                            this.loadData()
                        }
                    })
                }
            })
        },
        numberFormatBn (number) {
            return numberFormatBn(number, this, { useGrouping: false })
        },
        setData (data) {
            if (data.district_id) {
                this.upazilaList = this.getUpazilaList(data.district_id)
            }
            this.search = Object.assign({}, this.search, data)
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        view (item) {
            this.currentItem = item
            this.$bvModal.show('view-modal')
        },
        resetId () {
            this.currentItem = null
        },
        async searchData (payload) {
            // this.search = payload
            await this.loadData()
        },
        async loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true })

            await RestApi.getData(agriMarketingServiceBaseUrl, ggProfileList, params).then((response) => {
                if (response.success) {
                    if (response.data.data.length > 0) {
                        response.data.data.map(item => {
                            const type = Custom.ginnerGrower.find(el => el.value === item.type)
                            if (typeof type !== 'undefined') {
                                item.type_en = type.text_en
                                item.type_bn = type.text_bn
                            }
                            const district = this.districtList.find(el => el.value === item.district_id)
                            if (typeof district !== 'undefined') {
                                item.district_en = district.text_en
                                item.district_bn = district.text_bn
                            }
                            const upazila = this.getUpazilaList().find(el => el.value === item.upazilla_id)
                            if (typeof upazila !== 'undefined') {
                                item.upazila_en = upazila.text_en
                                item.upazila_bn = upazila.text_bn
                            }
                            const organization = this.orgList.find(el => el.value === item.org_id)
                            if (typeof organization !== 'undefined') {
                                item.org_en = organization.text_en
                                item.org_bn = organization.text_bn
                            }
                            const region = this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1).find(el => el.value === item.region_id)
                            if (typeof region !== 'undefined') {
                                item.region_en = region.text_en
                                item.region_bn = region.text_bn
                            }
                            const zone = this.zoneList.find(el => el.value === item.zone_id)
                            if (typeof zone !== 'undefined') {
                                item.zone_en = zone.text_en
                                item.zone_bn = zone.text_bn
                            }
                            const unit = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1).find(el => el.value === item.unit_id)
                            if (typeof unit !== 'undefined') {
                                item.unit_en = unit.text_en
                                item.unit_bn = unit.text_bn
                            }
                            return item
                        })
                    }
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }).catch((error) => {
                if (error.response) {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        }
    }
}
</script>

<style>
.expand-column {
    min-width: 160px;
}
</style>

<template>
    <item-view :prop-data="itemsToView"/>
</template>
<script>
import ItemView from '@/components/structure/itemView'
import { helpers } from '@/mixins/helper-functions'
import customObject from '@/store/custom.js'
export default {
    name: 'Show',
    props: ['item'],
    components: { ItemView },
    created () {
        if (this.id) {
            const tmp = this.getAppInfo()
            Object.freeze(tmp)
            this.smartCardApplication = this.formatList(tmp)
        }
    },
    data () {
        return {
            smartCardApplication: {},
            showData: {
                official: {},
                personal: {},
                address: {},
                land: {},
                application: {}
            }
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            const personalFormatted = [
                {
                    title1: this.item.type === 1 ? this.$t('globalTrans.company_name') : this.$t('globalTrans.name'),
                    value1: this.isBn ? this.item.name_bn : this.item.name,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('globalUserData.mobile_no'),
                    value1: this.$options.filters.mobileNumber(this.item.mobile_no),
                    title2: this.item.type === 1 ? this.$t('cotton_ginner_grower.ginnerName') : this.$t('cotton_ginner_grower.father_name'),
                    value2: this.isBn ? this.item.father_name_bn : this.item.father_name
                },
                {
                    title1: this.$t('globalTrans.type'),
                    value1: this.isBn ? this.item.type_bn : this.item.type_en,
                    title2: this.$t('globalTrans.region'),
                    value2: this.isBn ? this.item.region_bn : this.item.region_en
                },
                {
                    title1: this.$t('cotton_ginner_grower.zone'),
                    value1: this.isBn ? this.item.zone_bn : this.item.zone_en,
                    title2: this.$t('cotton_ginner_grower.unit'),
                    value2: this.isBn ? this.item.unit_bn : this.item.unit_en
                },
                {
                    title1: this.$t('globalTrans.district'),
                    value1: this.isBn ? this.item.district_bn : this.item.district_en,
                    title2: this.$t('globalTrans.upazila'),
                    value2: this.isBn ? this.item.upazila_bn : this.item.upazila_en
                },
                {
                    title1: this.$t('cotton_ginner_grower.address'),
                    value1: this.isBn ? this.item.address_bn : this.item.address,
                    title2: this.$t('externalUserIrrigation.nid'),
                    value2: this.item.nid ? this.$options.filters.numberConvert(this.item.nid.toString()) : ''
                }
            ]
            return {
                title: '',
                data:
                    [
                        {
                            title: this.$t('cotton_ginner_grower.profile'),
                            type: 'table',
                            data: personalFormatted
                        }
                    ]
            }
        }
    },
    methods: {
        viewItems () {
            return this.itemsToView
        },
        getAppInfo () {
            const tmp = typeof this.list !== 'undefined' ? this.list : this.$store.state.ExternalUserIrrigation.smartCardApplicationLists
            const list = Object.freeze(tmp)
            return list.find(item => item.id === this.id)
        },
        formatList (data) {
            const commonObj = typeof this.commonObj !== 'undefined' ? this.commonObj : this.$store.state.ExternalUserIrrigation.commonObj
            const orgList = commonObj.organizationProfileList
            const divisionList = commonObj.divisionList
            const districtList = commonObj.districtList
            const upazilaList = commonObj.upazilaList
            const unionList = commonObj.unionList

            const genderList = customObject.genderList
            const reissueStatusList = customObject.reissueStatus
            const smartCardStatusList = customObject.smartCardStatus
            const maritalStatusList = customObject.maritalStatus
            const orgPro = orgList.find(org => org.value === data.org_id)
            const division = divisionList.find(item => item.value === data.far_division_id)
            const district = districtList.find(item => item.value === data.far_district_id)
            const upazilla = upazilaList.find(item => item.value === data.far_upazilla_id)
            const union = unionList.find(item => item.value === data.far_union_id)
            const gender = genderList.find(item => item.value === data.gender)
            const reissueStatus = reissueStatusList.find(item => item.id === data.reissue_status)
            const smartCardStatus = smartCardStatusList.find(item => item.id === data.status)
            const maritalStatus = maritalStatusList.find(item => item.value === data.marital_status)

            const mergedObj = Object.assign({}, data,
                { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn },
                { division_name: division.text_en, division_name_bn: division.text_bn },
                { district_name: district.text_en, district_name_bn: district.text_bn },
                { upazilla_name: upazilla.text_en, upazilla_name_bn: upazilla.text_bn },
                { union_name: union.text_en, union_name_bn: union.text_bn },
                { gender_en: gender.text_en, gender_bn: gender.text_bn },
                { reissue_status_en: reissueStatus ? reissueStatus.name : 'N/A', reissue_status_bn: reissueStatus ? reissueStatus.name_bn : 'N/A' },
                { status_en: smartCardStatus ? smartCardStatus.name : 'N/A', status_bn: smartCardStatus ? smartCardStatus.name_bn : 'N/A' },
                { marital_status_en: maritalStatus.text_en, marital_status_bn: maritalStatus.text_bn }
            )
            const personalItems = [
                'name', 'name_bn',
                'email', 'mobile_no', 'date_of_birth',
                'marital_status_en', 'marital_status_bn',
                'father_name', 'father_name_bn',
                'mother_name', 'mother_name_bn',
                'gender_en', 'gender_bn',
                'spouse_name', 'spouse_name_bn',
                'no_of_child', 'earnings', 'nid', 'qualification', 'attachment'
            ]

            const addressItems = [
                'district_name', 'district_name_bn',
                'division_name', 'division_name_bn',
                'upazilla_name', 'upazilla_name_bn',
                'union_name', 'union_name_bn', 'ward_no',
                'far_village', 'far_village_bn'
            ]

            const landItems = [
                'barga_land',
                'crop_plan',
                'crop_plan_bn',
                'lease_land',
                'owned_land',
                'total_land'
            ]

            const officialItems = [
                'org_name',
                'org_name_bn',
                'training_info',
                'id_serial',
                'application_id'
            ]

            const applicationItems = [
                'reissue_status_en',
                'reissue_status_bn',
                'status_en',
                'status_bn'
            ]

            const otherItems = [
                'review_note',
                'review_note_bn',
                'reject_note',
                'reject_note_bn'
            ]

            const application = helpers.filterObjectByArrayItem(mergedObj, applicationItems)
            const official = helpers.filterObjectByArrayItem(mergedObj, officialItems)
            const personal = helpers.filterObjectByArrayItem(mergedObj, personalItems)
            const address = helpers.filterObjectByArrayItem(mergedObj, addressItems)
            const land = helpers.filterObjectByArrayItem(mergedObj, landItems)
            const other = helpers.filterObjectByArrayItem(mergedObj, otherItems)
            this.showData = { application, official, personal, address, land, other }
            return mergedObj
        }
    }
}
</script>
<style>
/* .hidden_header {
    display: none
}
.card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
}
[dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
}
.report-name{
    font-weight: bold !important;
    text-transform: uppercase;
}
.my-btn{
    padding: 2px !important;
} */
</style>
